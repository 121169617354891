export const checkForUpdates = (): void => {
    if (typeof window === 'undefined' || window.location.hostname !== 'localhost') return;

    const fetchBundleHeaders = async (): Promise<void> => {
        try {
            const response = await fetch('/ui/bundle.js', { method: 'HEAD', cache: 'no-store', keepalive: true });
            if (!response.ok) {
                console.debug('Failed to fetch bundle.js headers');
                return;
            }

            const newETag = response.headers.get('ETag') || response.headers.get('Last-Modified');
            if (!newETag) {
                console.debug('ETag or Last-Modified header not found');
                return;
            }

            const currentETag = sessionStorage.getItem('bundleETag');
            if (currentETag !== newETag) {
                console.debug('New bundle detected, reloading...');
                sessionStorage.setItem('bundleETag', newETag);
                if (currentETag) window.location.reload();
            }
        } catch (error) {
            console.debug('Error during bundle update check:', error instanceof Error ? error.message : error);
        } finally {
            setTimeout(fetchBundleHeaders, 2000);
        }
    };

    fetchBundleHeaders();
};